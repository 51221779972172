<template>
  <div>
    <div class="mb-2">
      <cropper
        :class="{ 'upload-example-cropper': true }"
        :stencil-props="stencilProps"
        :src="thumbnail"
        @change="onChangeCropper"
        :canvas="false"
        :check-orientation="false"
      />
      <!--            <img :src="thumbnail"  alt="new brand image"/>-->
    </div>
    <div class="wa__modal__form">
      <div>
        <b-button
          v-if="hiddenInput"
          text="select your file"
          icon="WMi-camera"
          class="w-100"
          variant="danger"
          @click="$refs.fileInput.$refs.input.click()"
        >
          <span>
            <i class="WMi-camera" />
          </span>
        </b-button>
        <b-form-file
          ref="fileInput"
          accept="image/jpeg, image/png"
          placeholder="Pick an Image"
          prepend-icon="WMi-camera"
          :label="label"
          :style="[
            hiddenInput
              ? {
                visibility: 'hidden',
                height: '0',
                padding: '0',
              }
              : '',
          ]"
          :dark="dark"
          @input="changeImage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Cropper } from 'vue-advanced-cropper'
import { BButton, BFormFile } from 'bootstrap-vue'
import DefaultImage from '@/assets/images/misc/1920.png'
import 'vue-advanced-cropper/dist/style.css'

export default {
    components: {
        Cropper,
        BFormFile,
        BButton,
    },
    props: {
        url: {
            default: DefaultImage,
        },
        value: {
            default: () => ({}),
        },
        stencilProps: {
            default: () => ({ aspectRatio: 0, checkImageOrigin: false }),
        },
        crop: {
            default: () => {},
        },
        label: {
            default: '',
        },
        dark: {
            default: true,
        },
        hiddenInput: {
            default: true,
        },
    },
    computed: {
        form: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        thumbnail: {
            get() {
                return this.url
            },
            set(value) {
                this.$emit('update:url', value)
            },
        },
    },
    methods: {
        changeImage(file) {
            if (file) {
                this.form = { ...this.value, ...{ file } }
                const reader = new FileReader()
                reader.onload = file => {
                    this.thumbnail = file.target.result
                }
                reader.readAsDataURL(file)
            }
        },
        onChangeCropper({ coordinates }) {
            this.form = { ...this.value, ...{ crop: coordinates } }
            this.$emit('update:crop', coordinates)
        },
    },
}
</script>
<style scoped>
/* dialog  */
.wa__modal__image {
  width: 250px;
  margin: 0 auto;
  border: 1px dashed var(--color-gray);
}
.upload-example-cropper {
  border: 1px solid #afafaf;
  border-radius: 5px;
  background-color: white !important;
  height: 250px;
}
.upload-example-cropper.red {
  border: 1px solid red;
}
</style>
