export default {
  __openModal({ state, rootState }, data) {
    // data: {name, rel, model, form_data, data, modal_pop_data, type}
    let can = true;
    if (can) {
      data.name = "modal_" + data.name;
      console.log(state.modals.findIndex((x) => x.name === data.name))
      if (state.modals.findIndex((x) => x.name === data.name) === -1) {
        state.modals.push(data);
        let module = data.name.split("_");
        if (module.length > 2) {
          if (data.rel) {
            rootState[module[1]].relation = data.rel;
            state.rel.push(data.rel);
          }
          if (data.model) {
            rootState[module[1]].current_model = data.model;
            state.model.push(data.model);
          }
          // rootState[module[1]].page_type = data.page_type
          //   ? data.page_type
          //   : "modal";
        }
      }
    }
  },
  $_closeModal({ state, rootState }) {
    if (state.modals.length) {
      let last_data = [...state.modals].pop();

      if (state.modals.length > 1) {
        state.modals[state.modals.length - 2]["last_modal_data"] = last_data;
      }

      if (last_data.name.split("_").length > 2) {
        let module = last_data.name.split("_")[1];

        if (state.modals.length <= 1) {
          rootState[module].page_type = "";
        }

        if (last_data.rel) {
          state.rel.pop();
          if (state.rel.length) {
            rootState[module].relation = state.rel[state.rel.length - 1];
          } else {
            rootState[module].relation = {};
          }
        }
        if (last_data.model) {
          state.model.pop();
          if (state.model.length) {
            rootState[module].current_model =
              state.model[state.model.length - 1];
          } else {
            rootState[module].current_model = {};
          }
        }
      }

      state.modals.pop();
    }
  },

  $_dialog: ({ commit, dispatch }, properties) => {
    properties["name"] = "modal_dialog";
    dispatch("__openModal", properties);

    commit("SET_DIALOG_TYPE", properties.type);
    commit("SET_DIALOG_PROPERTIES", properties);
  },

  $_helper: ({ dispatch }, slug) => {
    let data = {};
    data["name"] = "modal_helper";
    data["slug"] = slug;
    dispatch("__openModal", data);
  },
};
