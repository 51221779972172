export default [
    // *===============================================---*
    // *--------- Access Level ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/access-level/list",
        name: "access-level-list",
        component: () => import("@/views/apps/accessLevel/AccessLevelList.vue"),
    },
    {
        path: "/access-level/add",
        name: "access-level-add",
        component: () => import("@/views/apps/accessLevel/AccessLevelAdd.vue"),
    },
    {
        path: "/access-level/assign",
        name: "access-level-assign",
        component: () => import("@/views/apps/accessLevel/AssignRole.vue"),
    },
    {
        path: "/access-level/permissions-assign/:id",
        name: "access-level-permissions",
        component: () => import("@/views/apps/accessLevel/PermissionAssign.vue"),
    },
    // *===============================================---*
    // *--------- USER ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/users/list",
        name: "apps-users-list",
        component: () => import("@/views/apps/user/UsersList.vue"),
    },
    {
        path: "/apps/users/view/:id",
        name: "apps-users-view",
        component: () => import("@/views/apps/user/UsersView.vue"),
    },
    {
        path: "/apps/users/edit/:id",
        name: "apps-users-edit",
        component: () => import("@/views/apps/user/UsersEdit.vue"),
    },
    {
        path: "/apps/users/add",
        name: "apps-users-add",
        component: () => import("@/views/apps/user/UsersEdit.vue"),
    },
    {
        path: "/apps/user/:id/change-password",
        name: "apps-user-change-password",
        component: () => import("@/views/apps/user/UserChangePassword.vue"),
    },
    // *===============================================---*
    // *--------- ARTISTS ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/artists/list",
        name: "apps-artists-list",
        component: () => import("@/views/apps/artist/ArtistsList.vue"),
    },
    {
        path: "/apps/artists/view/:id",
        name: "apps-artists-view",
        component: () => import("@/views/apps/artist/ArtistsView.vue"),
    },
    {
        path: "/apps/artists/edit/:id",
        name: "apps-artists-edit",
        component: () => import("@/views/apps/artist/ArtistsEdit.vue"),
    },
    {
        path: "/apps/artists/add",
        name: "apps-artists-add",
        component: () => import("@/views/apps/artist/ArtistsEdit.vue"),
    },
    // *===============================================---*
    // *--------- COMPOSER ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/composer/list",
        name: "apps-composer-list",
        component: () => import("@/views/apps/composer/ComposerList.vue"),
    },
    {
        path: "/apps/composer/view/:id",
        name: "apps-composer-view",
        component: () => import("@/views/apps/composer/ComposerView.vue"),
    },
    {
        path: "/apps/composer/edit/:id",
        name: "apps-composer-edit",
        component: () => import("@/views/apps/composer/ComposerEdit.vue"),
    },
    {
        path: "/apps/composer/add",
        name: "apps-composer-add",
        component: () => import("@/views/apps/composer/ComposerEdit.vue"),
    },
    {
        path: "/apps/composer-special-item/add",
        name: "composer-special-item-add",
        component: () => import("@/views/apps/composer/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/composer-special-item/edit/:id",
        name: "composer-special-item-edit",
        component: () => import("@/views/apps/composer/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/composer-special-item/list",
        name: "composer-special-item-list",
        component: () => import("@/views/apps/composer/SpecialItemList.vue"),
    },
    // *===============================================---*
    // *--------- MUSIC ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/music/list",
        name: "apps-musics-list",
        component: () => import("@/views/apps/music/MusicsList.vue"),
    },
    {
        path: "/apps/music/view/:id",
        name: "apps-musics-view",
        component: () => import("@/views/apps/music/MusicsView.vue"),
    },
    {
        path: "/apps/music/edit/:id",
        name: "apps-musics-edit",
        component: () => import("@/views/apps/music/MusicEdit.vue"),
    },
    {
        path: "/apps/music/add",
        name: "apps-musics-add",
        component: () => import("@/views/apps/music/MusicEdit.vue"),
    },
    {
        path: "/apps/music-special-item/add",
        name: "music-special-item-add",
        component: () => import("@/views/apps/music/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/music-special-item/edit/:id",
        name: "music-special-item-edit",
        component: () => import("@/views/apps/music/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/music-special-item/list",
        name: "music-special-item-list",
        component: () => import("@/views/apps/music/SpecialItemList.vue"),
    },
    // *===============================================---*
    // *--------- VIDEO ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/video/list",
        name: "apps-videos-list",
        component: () => import("@/views/apps/video/VideosList.vue"),
    },
    {
        path: "/apps/video/view/:id",
        name: "apps-videos-view",
        component: () => import("@/views/apps/video/VideosView.vue"),
    },
    {
        path: "/apps/video/edit/:id",
        name: "apps-videos-edit",
        component: () => import("@/views/apps/video/VideoEdit.vue"),
    },
    {
        path: "/apps/video/add",
        name: "apps-videos-add",
        component: () => import("@/views/apps/video/VideoEdit.vue"),
    },
    {
        path: "/apps/video-special-item/add",
        name: "video-special-item-add",
        component: () => import("@/views/apps/video/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/video-special-item/edit/:id",
        name: "video-special-item-edit",
        component: () => import("@/views/apps/video/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/video-special-item/list",
        name: "video-special-item-list",
        component: () => import("@/views/apps/video/SpecialItemList.vue"),
    },
    // *===============================================---*
    // *--------- RADIO STATION ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/radio-station/list",
        name: "apps-radio-station-list",
        component: () => import("@/views/apps/radio-station/RadioStationList.vue"),
    },
    {
        path: "/apps/radio-station/edit/:id",
        name: "apps-radio-station-edit",
        component: () => import("@/views/apps/radio-station/RadioStationEdit.vue"),
    },
    {
        path: "/apps/radio-station/add",
        name: "apps-radio-station-add",
        component: () => import("@/views/apps/radio-station/RadioStationEdit.vue"),
    },
    {
        path: "/apps/radio-station-special-item/add",
        name: "apps-radio-station-special-item-add",
        component: () => import("@/views/apps/radio-station/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/radio-station-special-item/list",
        name: "apps-radio-station-special-item-list",
        component: () => import("@/views/apps/radio-station/SpecialItemList.vue"),
    },
    // *===============================================---*
    // *--------- SPECIAL ITEM ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/special-item/list",
        name: "apps-special-item-list",
        component: () => import("@/views/apps/special-item/SpecialItemList.vue"),
    },
    {
        path: "/apps/special-item/edit/:id",
        name: "apps-special-item-edit",
        component: () => import("@/views/apps/special-item/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/special-item/add",
        name: "apps-special-item-add",
        component: () => import("@/views/apps/special-item/SpecialItemEdit.vue"),
    },
    // *===============================================---*
    // *--------- PLAY LIST ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/play-list/list",
        name: "apps-play-list-list",
        component: () => import("@/views/apps/play-list/PlayListList.vue"),
    },
    {
        path: "/apps/play-list/view/:id",
        name: "apps-play-list-view",
        component: () => import("@/views/apps/play-list/PlayListView.vue"),
    },
    {
        path: "/apps/play-list/edit/:id",
        name: "apps-play-list-edit",
        component: () => import("@/views/apps/play-list/PlayListEdit.vue"),
    },
    {
        path: "/apps/play-list/add",
        name: "apps-play-list-add",
        component: () => import("@/views/apps/play-list/PlayListEdit.vue"),
    },
    {
        path: "/apps/play-list/convert",
        name: "apps-play-list-convert",
        component: () => import("@/views/apps/play-list/PlayListConvert.vue"),
    },
    {
        path: "/apps/playlist-special-item/add",
        name: "playlist-special-item-add",
        component: () => import("@/views/apps/play-list/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/playlist-special-item/edit/:id",
        name: "playlist-special-item-edit",
        component: () => import("@/views/apps/play-list/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/playlist-special-item/list",
        name: "playlist-special-item-list",
        component: () => import("@/views/apps/play-list/SpecialItemList.vue"),
    },
    // *===============================================---*
    // *--------- genres ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/genres/list",
        name: "apps-genres-list",
        component: () => import("@/views/apps/genre/GenreList.vue"),
    },
    {
        path: "/apps/genres/edit/:id",
        name: "apps-genres-edit",
        component: () => import("@/views/apps/genre/GenreEdit.vue"),
    },
    {
        path: "/apps/genres/add",
        name: "apps-genres-add",
        component: () => import("@/views/apps/genre/GenreEdit.vue"),
    },
    // *===============================================---*
    // *--------- moods ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/moods/list",
        name: "apps-moods-list",
        component: () => import("@/views/apps/moods/MoodList.vue"),
    },
    {
        path: "/apps/moods/view/:id",
        name: "apps-moods-view",
        component: () => import("@/views/apps/moods/MoodView.vue"),
    },
    {
        path: "/apps/moods/edit/:id",
        name: "apps-moods-edit",
        component: () => import("@/views/apps/moods/MoodEdit.vue"),
    },
    {
        path: "/apps/moods/add",
        name: "apps-moods-add",
        component: () => import("@/views/apps/moods/MoodEdit.vue"),
    },
    {
        path: "/apps/moods-special-item/add",
        name: "moods-special-item-add",
        component: () => import("@/views/apps/moods/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/moods-special-item/edit/:id",
        name: "moods-special-item-edit",
        component: () => import("@/views/apps/moods/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/moods-special-item/list",
        name: "moods-special-item-list",
        component: () => import("@/views/apps/moods/SpecialItemList.vue"),
    },
    // *===============================================---*
    // *--------- slide show ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/slide-show/list",
        name: "apps-slide-show-list",
        component: () => import("@/views/apps/slide-show/SlideShowBrowse.vue"),
    },
    {
        path: "/apps/slide-show/edit/:id",
        name: "apps-slide-show-edit",
        component: () => import("@/views/apps/slide-show/SlideShowHome.vue"),
    },
    {
        path: "/apps/slide-show/add",
        name: "apps-slide-show-add",
        component: () => import("@/views/apps/slide-show/SlideShowHome.vue"),
    },
    {
        path: "/apps/slide-show/active-list",
        name: "apps-slide-show-active-list",
        component: () => import("@/views/apps/slide-show/SlideShowActive.vue"),
    },
    {
        path: "/apps/static-banners/list",
        name: "apps-static-banners-list",
        component: () => import("@/views/apps/slide-show/statics/SpecialItemList.vue"),
    },
    //static banner
    {
        path: "/apps/static-banners/edit/:id",
        name: "apps-static-banners-edit",
        component: () => import("@/views/apps/slide-show/statics/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/static-banners/add",
        name: "apps-static-banners-add",
        component: () => import("@/views/apps/slide-show/statics/SpecialItemEdit.vue"),
    },
    //commingsoon
    {
        path: "/apps/comming-soon/list",
        name: "apps-comming-soon-list",
        component: () => import("@/views/apps/slide-show/commingSoon/CommingSoonBrowse.vue"),
    },
    {
        path: "/apps/comming-soon/edit/:id",
        name: "apps-comming-soon-edit",
        component: () => import("@/views/apps/slide-show/commingSoon/CommingSoonHome.vue"),
    },
    {
        path: "/apps/comming-soon/add",
        name: "apps-comming-soon-add",
        component: () => import("@/views/apps/slide-show/commingSoon/CommingSoonHome.vue"),
    },
    {
        path: "/apps/comming-soon/active-list",
        name: "apps-comming-soon-active-list",
        component: () => import("@/views/apps/slide-show/commingSoon/CommingSoonActive.vue"),
    },
    // *===============================================---*
    // *--------- Monetization  ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/monetization/slider",
        name: "apps-monetization-slider",
        component: () => import("@/views/apps/monetization/Slider.vue"),
    },
    {
        path: "/apps/monetization/banner",
        name: "apps-monetization-banner",
        component: () => import("@/views/apps/monetization/Banner.vue"),
    },
    // *===============================================---*
    // *--------- Sticker Pack  ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/sticker-pack/add",
        name: "apps-sticker-pack-add",
        component: () => import("@/views/apps/sticker-pack/StickerEdit.vue"),
    },
    {
        path: "/apps/sticker-pack/list",
        name: "apps-sticker-pack-list",
        component: () => import("@/views/apps/sticker-pack/StickerList.vue"),
    },
    {
        path: "/apps/sticker-pack/edit/:id",
        name: "apps-sticker-pack-edit",
        component: () => import("@/views/apps/sticker-pack/StickerEdit.vue"),
    },
    {
        path: "/apps/sticker-pack/details/:id",
        name: "apps-sticker-pack-detail",
        component: () => import("@/views/apps/sticker-pack/StickerView.vue"),
    },
    // *===============================================---*
    // *--------- Trending  ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/trending/real",
        name: "apps-trending-real",
        component: () => import("@/views/apps/trending/Real.vue"),
    },
    {
        path: "/apps/trending/real/details/:id/:type",
        name: "apps-trending-real-details",
        component: () => import("@/views/apps/trending/RealDetails.vue"),
    },
    {
        path: "/apps/trending/custom/coefficient",
        name: "apps-trending-coefficient",
        component: () => import("@/views/apps/trending/Options.vue"),
    },
    {
        path: "/apps/trending/custom/insert",
        name: "apps-trending-insert",
        component: () => import("@/views/apps/trending/Insert.vue"),
    },
    {
        path: "/apps/trending/custom/list/:id?",
        name: "apps-trending-list",
        component: () => import("@/views/apps/trending/List.vue"),
    },
    {
        path: "/apps/trending/custom/detail/:id?",
        name: "apps-trending-custom-detail",
        component: () => import("@/views/apps/trending/customDetail.vue"),
    },
    {
        path: "/apps/trending/final/list",
        name: "apps-trending-final",
        component: () => import("@/views/apps/trending/Final.vue"),
    },
    {
        path: "/apps/trending/next-final/list",
        name: "apps-trending-next-final",
        component: () => import("@/views/apps/trending/nextFinal.vue"),
    },
    // *===============================================---*
    // *--------- Notification ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/notification/add",
        name: "apps-notification-add",
        component: () => import("@/views/apps/notification/NotificationHome.vue"),
    },
    {
        path: "/apps/notification/list",
        name: "apps-notification-list",
        component: () => import("@/views/apps/notification/NotificationList.vue"),
    },
    // *===============================================---*
    // *--------- News ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/news/list",
        name: "apps-news-list",
        component: () => import("@/views/apps/news/NewsList.vue"),
    },
    {
        path: "/apps/news/view/:id",
        name: "apps-news-view",
        component: () => import("@/views/apps/news/NewsView.vue"),
    },
    {
        path: "/apps/news/edit/:id",
        name: "apps-news-edit",
        component: () => import("@/views/apps/news/NewsEdit.vue"),
    },
    {
        path: "/apps/news/add",
        name: "apps-news-add",
        component: () => import("@/views/apps/news/NewsEdit.vue"),
    },
    {
        path: "/apps/news-special-item/add",
        name: "news-special-item-add",
        component: () => import("@/views/apps/news/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/news-special-item/edit/:id",
        name: "news-special-item-edit",
        component: () => import("@/views/apps/news/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/news-special-item/list",
        name: "news-special-item-list",
        component: () => import("@/views/apps/news/SpecialItemList.vue"),
    },
    // *===============================================---*
    // *--------- Latest ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/latest-special-item/add",
        name: "latest-special-item-add",
        component: () => import("@/views/apps/latest/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/latest-special-item/edit/:id",
        name: "latest-special-item-edit",
        component: () => import("@/views/apps/latest/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/latest-special-item/list",
        name: "latest-special-item-list",
        component: () => import("@/views/apps/latest/SpecialItemList.vue"),
    },
    // *===============================================---*
    // *--------- Featured ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/featured-special-item/add",
        name: "featured-special-item-add",
        component: () => import("@/views/apps/featured/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/featured-special-item/edit/:id",
        name: "featured-special-item-edit",
        component: () => import("@/views/apps/featured/SpecialItemEdit.vue"),
    },
    {
        path: "/apps/featured-special-item/list",
        name: "featured-special-item-list",
        component: () => import("@/views/apps/featured/SpecialItemList.vue"),
    },
    // *===============================================---*
    // *--------- Live Chat ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/live-chat/main",
        name: "apps-live-chat",
        component: () => import("@/views/apps/live-chat/main.vue"),
    },
    {
        path: "/apps/live-chat/list",
        name: "apps-live-chat-list",
        component: () => import("@/views/apps/live-chat/liveChatList.vue"),
    },
    {
        path: "/apps/live-chat/add",
        name: "apps-live-chat-add",
        component: () => import("@/views/apps/live-chat/SetLiveChat.vue"),
    },
    {
        path: "/apps/live-chat/edit/:id",
        name: "apps-live-chat-edit",
        component: () => import("@/views/apps/live-chat/SetLiveChat.vue"),
    },
    // *===============================================---*
    // *--------- Google Ads Setting ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/adds/setting",
        name: "apps-google-adds-setting",
        component: () => import("@/views/apps/googleAds/Setting.vue"),
    },
    // *===============================================---*
    // *--------- Comment ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/comment/list",
        name: "apps-comment-list",
        component: () => import("@/views/apps/comment/CommentList.vue"),
    },
    // {
    //     path: '/apps/comment/view/:id',
    //     name: 'apps-comment-view',
    //     component: () => import('@/views/apps/comment/CommentView.vue'),
    // },
    // {
    //     path: '/apps/comment/edit/:id',
    //     name: 'apps-comment-edit',
    //     component: () => import('@/views/apps/comment/CommentEdit.vue'),
    // },
    // {
    //     path: '/apps/comment/add',
    //     name: 'apps-comment-add',
    //     component: () => import('@/views/apps/comment/CommentEdit.vue'),
    // },
    // *===============================================---*
    // *--------- Post ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/post/list",
        name: "apps-post-list",
        component: () => import("@/views/apps/post/PostList.vue"),
    },
    {
        path: "/apps/post/view/:id",
        name: "apps-post-view",
        component: () => import("@/views/apps/post/PostView.vue"),
    },
    {
        path: "/apps/post/edit/:id",
        name: "apps-post-edit",
        component: () => import("@/views/apps/post/PostEdit.vue"),
    },
    {
        path: "/apps/post/add",
        name: "apps-post-add",
        component: () => import("@/views/apps/post/PostEdit.vue"),
    },
    // *===============================================---*
    // *--------- Story ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/story/list",
        name: "apps-story-list",
        component: () => import("@/views/apps/story/StoryList.vue"),
    },
    {
        path: "/apps/story/view/:id",
        name: "apps-story-view",
        component: () => import("@/views/apps/story/StoryView.vue"),
    },
    {
        path: "/apps/story/edit/:id",
        name: "apps-story-edit",
        component: () => import("@/views/apps/story/StoryEdit.vue"),
    },
    {
        path: "/apps/story/add",
        name: "apps-story-add",
        component: () => import("@/views/apps/story/StoryEdit.vue"),
    },
    // *===============================================---*
    // *--------- Version ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/version/list",
        name: "apps-version-list",
        component: () => import("@/views/apps/version/VersionEdit.vue"),
    },
    // *===============================================---*
    // *--------- Email Templates ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/email-templates/list",
        name: "apps-email-templates-list",
        component: () => import("@/views/apps/email/TemplateList.vue"),
    },
    {
        path: "/apps/email-templates/edit/:id",
        name: "apps-email-templates-edit",
        component: () => import("@/views/apps/email/TemplateEdit.vue"),
    },
    {
        path: "/apps/email-templates/add",
        name: "apps-email-templates-add",
        component: () => import("@/views/apps/email/TemplateEdit.vue"),
    },
    // *===============================================---*
    // *--------- Static Images ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/setting/update",
        name: "apps-setting-update",
        component: () => import("@/views/apps/setting/SettingEdit.vue"),
    },
    {
        path: "/apps/email-templates/edit/:id",
        name: "apps-email-templates-edit",
        component: () => import("@/views/apps/email/TemplateEdit.vue"),
    },
    {
        path: "/apps/email-templates/add",
        name: "apps-email-templates-add",
        component: () => import("@/views/apps/email/TemplateEdit.vue"),
    },
    // *===============================================---*
    // *--------- Wallet ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/wallet/users-wallet",
        name: "apps-wallet-users-wallet",
        component: () => import("@/views/apps/wallet/users-wallet.vue"),
    },
    {
        path: "/apps/wallet/exchange-rate",
        name: "apps-wallet-exchange-rate",
        component: () => import("@/views/apps/wallet/exchange-rate.vue"),
    },
    {
        path: "/apps/wallet/admin-log",
        name: "apps-wallet-admin-log",
        component: () => import("@/views/apps/wallet/admin-log.vue"),
    },
    {
        path: "/apps/wallet/admin-log/:id",
        name: "apps-wallet-admin-log-detail",
        component: () => import("@/views/apps/wallet/admin-log-detail.vue"),
    },
    {
        path: "/apps/wallet/totals",
        name: "apps-wallet-totals",
        component: () => import("@/views/apps/wallet/totals.vue"),
    },
    {
        path: "/apps/wallet/total-payments/:id",
        name: "apps-wallet-total-payments-detail",
        component: () => import("@/views/apps/wallet/total-payments-detail.vue"),
    },
    {
        path: "/apps/wallet/total-transactions/:id",
        name: "apps-wallet-total-transactions-detail",
        component: () => import("@/views/apps/wallet/total-transactions-detail.vue"),
    },
    {
        path: "/apps/wallet/referal-code",
        name: "apps-wallet-referal-code",
        component: () => import("@/views/apps/wallet/referal-code.vue"),
    },
    // *===============================================---*
    // *--------- Withdraw ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/withdraw/config",
        name: "apps-withdraw-config",
        component: () => import("@/views/apps/withdraw/config.vue"),
    },
    {
        path: "/apps/withdraw/requests",
        name: "apps-withdraw-requests",
        component: () => import("@/views/apps/withdraw/requests.vue"),
    },
    {
        path: "/apps/withdraw/response/:id",
        name: "apps-withdraw-response",
        component: () => import("@/views/apps/withdraw/response.vue"),
    },
    // *===============================================---*
    // *--------- Store ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/store-slide-show/list",
        name: "apps-store-slide-show-list",
        component: () => import("@/views/apps/store/slide-show/SlideShowBrowse.vue"),
    },
    {
        path: "/apps/store-slide-show/edit/:id",
        name: "apps-store-slide-show-edit",
        component: () => import("@/views/apps/store/slide-show/SlideShowHome.vue"),
    },
    {
        path: "/apps/store-slide-show/add",
        name: "apps-store-slide-show-add",
        component: () => import("@/views/apps/store/slide-show/SlideShowHome.vue"),
    },
    {
        path: "/apps/store-slide-show/active-list",
        name: "apps-store-slide-show-active-list",
        component: () => import("@/views/apps/store/slide-show/SlideShowActive.vue"),
    },
    // *===============================================---*
    // *--------- contest ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/contest/list",
        name: "apps-contest-list",
        component: () => import("@/views/apps/contest/contestList.vue"),
    },
    {
        path: "/apps/contest/edit/:id",
        name: "apps-contest-edit",
        component: () => import("@/views/apps/contest/contestEdit.vue"),
    },
    {
        path: "/apps/contest/add",
        name: "apps-contest-add",
        component: () => import("@/views/apps/contest/contestEdit.vue"),
    },
    // *===============================================---*
    // *--------- contest group ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/contest-group/list/:contestId",
        name: "apps-contest-group-list",
        component: () => import("@/views/apps/contest/contestGroup/groupList.vue"),
    },
    {
        path: "/apps/contest-group/edit/:contestId/:groupId",
        name: "apps-contest-group-edit",
        component: () => import("@/views/apps/contest/contestGroup/groupEdit.vue"),
    },
    {
        path: "/apps/contest-group/add/:contestId",
        name: "apps-contest-group-add",
        component: () => import("@/views/apps/contest/contestGroup/groupEdit.vue"),
    },
    {
        path: "/apps/contest-group/participants/:groupId/:contestId",
        name: "apps-contest-group-participants",
        component: () => import("@/views/apps/contest/contestGroup/groupParticipants.vue"),
    },
    {
        path: "/apps/contest-group/participants/:participantId",
        name: "apps-contest-group-participants-detail",
        component: () => import("@/views/apps/contest/contestGroup/participantDetails.vue"),
    },
    // *===============================================---*
    // *--------- contest stage ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/contest-stage/list/:contestId",
        name: "apps-contest-stage-list",
        component: () => import("@/views/apps/contest/contestStage/stageList.vue"),
    },
    {
        path: "/apps/contest-stage/edit/:id",
        name: "apps-contest-stage-edit",
        component: () => import("@/views/apps/contest/contestStage/stageEdit.vue"),
    },
    {
        path: "/apps/contest-stage/add/:contestId",
        name: "apps-contest-stage-add",
        component: () => import("@/views/apps/contest/contestStage/stageEdit.vue"),
    },
    {
        path: "/apps/contest-stage/add-song/:contestId/:stageId/:editPage",
        name: "apps-contest-stage-add-song",
        component: () => import("@/views/apps/contest/contestStage/addSongsStage.vue"),
    },
    {
        path: "/apps/contest-stage/add-song/:contestId/:stageId/:songId/:editPage",
        name: "apps-contest-stage-edit-song",
        component: () => import("@/views/apps/contest/contestStage/addSongsStage.vue"),
    },
    {
        path: "/apps/contest-stage/songs-list/:contestId/:stageId",
        name: "apps-contest-stage-list-song",
        component: () => import("@/views/apps/contest/contestStage/stageSongsList.vue"),
    },
    // *===============================================---*
    // *--------- contest participant ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/contest-participant/list/:id",
        name: "apps-contest-participant-list",
        component: () => import("@/views/apps/contest/contestParticipant/participantList.vue"),
    },
    // *===============================================---*
    // *--------- Store Items  ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/store-items/add",
        name: "apps-store-items-add",
        component: () => import("@/views/apps/store-items/storeItemEdit.vue"),
    },
    {
        path: "/apps/store-items/list",
        name: "apps-store-items-list",
        component: () => import("@/views/apps/store-items/storeItemList.vue"),
    },
    {
        path: "/apps/store-items/edit/:id",
        name: "apps-store-items-edit",
        component: () => import("@/views/apps/store-items/storeItemEdit.vue"),
    },
    {
        path: "/apps/store-items/details/:id",
        name: "apps-store-items-detail",
        component: () => import("@/views/apps/store-items/storeItemView.vue"),
    },
      // *===============================================---*
    // *--------- Store Items  ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/admin-log/list",
        name: "apps-admin-log-list",
        component: () => import("@/views/apps/admin-log/logList.vue"),
    },
];
