import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import '@/assets/Icons/css/fontello.css'
import './mixins/global'
import BasicModal from '@core/components/modal/BasicModal'
import ImageCropper from '@core/components/input/ImageCropper'

Vue.component('image-cropper', ImageCropper)
Vue.component('basic-modal', BasicModal)
Vue.component(FeatherIcon.name, FeatherIcon)
