export default {
    getDialogProperties:(state) => state.dialogProperties,
    getDialogType:(state) => state.dialogType,

    isModal: (state) => (...names) => {
        for (const name of names) {
            if (state.modals.findIndex(x => x.name == name) != -1) {
                return true;
            }
        }
        return false;
    },
    getModal:(state) => (name) => state.modals[state.modals.findIndex(x => x.name == name)],

    getModals:(state) => state.modals,
    getActiveModal:(state) => state.modals[state.modals.length],
    isActiveModal:(state) => (name) => state.modals[state.modals.length].name == name,
};
