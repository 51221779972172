export const getJson = data => ({
    id: data.id,
    name: data.name,
    name_en: data.name_en,
    country_id: data.country_id,
})
export const getArray = ({ data }) => {
    data = data.map(city => getJson(city))
    return { data }
}
