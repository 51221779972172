const urlGenerator = urls => function (routeAddress, parameters) {
    const urlPath = urls[routeAddress].split('/')
    const newPath = []
    for (const iterator of urlPath) {
        if (iterator.startsWith(':')) {
            if (iterator.endsWith('?')) {
                if (Object.prototype.hasOwnProperty.call(parameters, iterator.slice(1, -1))) {
                    newPath.push(parameters[iterator.slice(1, -1)])
                }
            } else {
                newPath.push(parameters[iterator.slice(1)])
            }
        } else {
            newPath.push(iterator)
        }
    }
    return `/api/${newPath.join('/')}`
}

export {
    urlGenerator,
}
