import axios from 'axios'
import url from '../url'
import { getArray } from '../resources/cityResource'

export default class CityRepository {
    async index(city) {
        const response = await axios.get(url('indexCities', { city }))
        if (response.status === 200) {
            return getArray(response.data)
        }
    }
}
