import { urlGenerator } from "@/utils/urlGenerator";

const urls = {
    //dashboard
    indexDashboardData: "admin-show-dashboard-data",
    //live chat
    addNewMessage: "admin-live-message",
    indexLiveChat: "admin-live-chat",
    storeLiveChat: "admin-live-chat",
    deleteLiveChat: "admin-live-chat/:live",
    showLiveChat: "admin-live-chat/:live",
    updateLiveChat: "admin-live-chat/:live",
    banUser: "admin-live-chat/:live/ban/:user",
    unbanUser: "admin-live-chat/:live/unban/:user",
    activeChat: "admin-live-chat/:live/activate-chat",
    deActiveChat: "admin-live-chat/:live/deactivate-chat",
    deActiveLive: "admin-live-chat/:live/deactivate-live",
    activeLive: "admin-live-chat/:live/activate-live",
    finishChat: "admin-live-chat/:live/finish-live",
    showActiveLive: "admin-show-active-live",
    uploadVideo: "vimeo",
    addLiveVideo: "admin-live-chat/:live/add-live-video/:vimeo",
    liveLastMessages: "admin-live-message/:liveId",
    deleteMsg: "admin-live-message/:live",
    pinMessage: "admin-live-message/:id/pin",
    unPinMessage: "admin-live-message/:id/unpin",
    pinedMessage: "admin-live-message/:live/pinned",
    banUserList: "admin-live-chat/:live/baned-list",
    liveChatLikes: "admin-live-chat/:id/set-likes",
    getLiveChatLikes: "admin-live-chat/:id/get-likes",
    liveChatBanner: "admin-live-chat/:id/ads/update-image",
    hideLiveBanner: "admin-live-chat/:id/ads/hide",
    showLiveBanner: "admin-live-chat/:id/ads/show",
    liveChatBannerStatus: "admin-live-chat/:id/ads/status",
    storeLiveStreamData: "admin-live-chat/stream-data",
    showLiveStreamData: "admin-live-chat/:live/stream-data",
    showLiveStreamRequests: "admin-live-chat/:live/stream-requests",
    inviteUserToStream: "admin-live-chat/stream-requests/invite",
    cancelInvite: "admin-live-chat/stream-requests/cancel-invite",
    endStream: "admin-live-chat/end-stream/:live/user/:user",
    removeRequest: "admin-live-chat/stream-requests/:id",
    resetLiveLikes: "admin-live-chat/:id/reset-likes",
    //accesslevel
    addRoles: "admin-roles",
    roleList: "admin-roles",
    showRole: "admin-role/:id",
    permissionsList: "admin-permissions",
    assignPermissions: "admin-give-permissions-to-role/:id",
    assignRoleToUser: "admin-assign-role-to-user",
    userRolesList: "admin-users-by-role",
    removeRoleFromUser: "admin-dismiss-role-from-user",
    deleteRole: "admin-role/:id",
    //home send to top
    sendToTopHome: "admin-features/sort/:id",
    // user
    indexUsers: "admin-users",
    showUser: "admin-users/:user",
    storeUser: "admin-users",
    updateUser: "admin-users/:user",
    destroyUser: "admin-users/:user",
    userChangePassword: "admin-change-password/:user",
    activateUser: "admin-activate_user/:user",
    // artist
    indexArtists: "admin-artists",
    showArtist: "admin-artists/:artist",
    storeArtist: "admin-artists",
    updateArtist: "admin-artists/:artist",
    // composer
    indexComposer: "admin-composers",
    showComposer: "admin-composers/:composer",
    storeComposer: "admin-composers",
    updateComposer: "admin-composers/:composerId",
    //sticker
    storeStickerPak: "admin-stickers/pack",
    indexStickerPacks: "admin-stickers/pack",
    showStickerPack: "admin-stickers/pack/:stickerId",
    updateStickerPack: "admin-stickers/pack/:stickerId",
    addNewStickerToPack: "admin-stickers/sticker",
    deleteStickerPack: "admin-stickers/pack/:stickerId",
    showSticker: "admin-stickers/sticker/:stickerId",
    updateSticker: "admin-stickers/sticker/:stickerId",
    deleteSticker: "admin-stickers/sticker/:stickerId",
    sendToTopStickerPack: "admin-stickers/pack/:stickerId/send-to-top",
    sendToTopSticker: "admin-stickers/sticker/:stickerId/send-to-top",
    // trending
    indexManualTrendsArtist: "admin-manual-trends/artist",
    storeManulaTrends: "admin-manual-trends",
    showManualTrends: "admin-show-manual-trend/:id",
    updateManualTrends: "admin-manual-trends/:id",
    deleteManulaTrends: "admin-manual-trends/:id",
    coefficientIndex: "admin-trends-setting",
    updateCoefficient: "admin-trends-setting",
    updateNextFinalTrend: "admin-edit-final-trends",

    // music
    indexMusics: "admin-music",
    showMusic: "admin-music/:music",
    storeMusic: "admin-music",
    updateMusic: "admin-music/:music",
    updateSortMusic: "admin-music/sort/:music",
    destroyMusic: "admin-music/:music",
    // video
    indexVideos: "admin-videos",
    showVideo: "admin-videos/:video",
    storeVideo: "admin-videos",
    destroyVideo: "admin-videos/:video",
    updateVideo: "admin-videos/:video",
    updateSortVideo: "admin-videos/sort/:video",
    // post
    indexPosts: "admin-posts",
    indexContestsPosts: "admin-contest/:contestId/posts",
    indexContestsStories: "admin-contest/:contestId/stories",
    showPost: "admin-posts/:post",
    storePost: "admin-posts",
    destroyPost: "admin-posts/:post",
    updatePost: "admin-posts/:post",
    verifyPost: "admin-posts/verify/:post",
    changePinPost: "admin-posts/pin/:post",
    // comment
    indexComments: "admin-comments",
    showComment: "admin-comments/:comment",
    // storeComment: 'comments',
    // destroyComment: 'comments/:comment',
    // updateComment: 'comments/:comment',
    verifyComment: "admin-comments/verify/:comment",
    // changePinComment: 'comments/pin/:comment',
    // story
    indexStories: "admin-stories",
    showStory: "admin-stories/:story",
    storeStory: "admin-stories",
    destroyStory: "admin-stories/:story",
    updateStory: "admin-stories/:story",
    verifyStory: "admin-stories/verify/:story",
    // news
    indexNews: "admin-news",
    showNews: "admin-news/:news",
    storeNews: "admin-news",
    destroyNews: "admin-news/:news",
    updateNews: "admin-news/:news",
    updateSortNews: "admin-news/sort/:news",
    // radio station
    indexRadioStation: "admin-radio-stations",
    showRadioStation: "admin-radio-stations/:radioStation",
    storeRadioStation: "admin-radio-stations",
    updateRadioStation: "admin-radio-stations/:radioStation",
    updateSortRadioStation: "admin-radio-stations/sort/:radioStation",
    destroyRadioStation: "admin-radio-stations/:radioStation",
    // play list
    indexPlaylist: "admin-playlists-all",
    showPlaylist: "admin-playlists/:playlist",
    updatePlaylist: "admin-playlists/:playlist",
    updateSortPlaylist: "admin-playlists/sort/:playlist",
    deletePlaylist: "admin-playlists/:playlist",
    storePlaylist: "admin-playlists",
    storeWithSlugPlaylist: "admin-playlists/:slug/slug",
    attachPlaylist: "admin-playlists/:playlist/attach",
    detachPlaylist: "admin-playlists/:playlist/detach",
    convertPlayList: "admin-playlists/to-mood",
    // admin playlist
    storeWithSlogAdminPlaylist: "admin-playlists/:slug/slug",
    indexAdminPlaylist: "admin-playlists",
    showAdminPlaylist: "admin-playlists/:playlist",
    updateAdminPlaylist: "admin-playlists/:playlist",
    updateSortAdminPlaylist: "playlists/sort/:playlist",
    deleteAdminPlaylist: "admin-playlists/:playlist",
    attachAdminPlaylist: "admin-playlists/:playlist/attach",
    detachAdminPlaylist: "admin-playlists/:playlist/detach",
    convertAdminPlayList: "admin-playlists/to-mood",
    //Notification
    sendNotification: "admin-send-notification",
    notifReport: "admin-notifications",
    //moods
    indexMoods: "admin-moods",
    storeWithSlugMoods: "admin-moods/:slug/slug",
    attachMoods: "admin-moods/:mood/attach",
    detachMoods: "admin-moods/:mood/detach",
    storeMoods: "admin-moods",
    updateSortMoods: "admin-moods/sort/:mood",
    deleteMood: "admin-moods/:mood",
    showMood: "admin-moods/:mood",
    updateMood: "admin-moods/:mood",
    //genre
    storeGenres: "admin-genres",
    indexGenres: "admin-genres",
    showGenres: "admin-genres/:genre",
    updateGenres: "admin-genres/:genre",
    deleteGenres: "admin-genres/:genre",
    //slideshow
    storeSlideShow: "admin-home-slideshow",
    showSlideshow: "admin-home-slideshow-all/:slideshow",
    updateSlideshow: "admin-home-slideshow/:slideshow",
    deleteSlideshow: "admin-home-slideshow/:slideshow",
    storeComingSoon: "admin-coming-soon",
    showComingSoon: "admin-coming-soon-all/:comingsoon",
    updateComingSoon: "admin-coming-soon/:comingsoon",
    deleteComingSoon: "admin-coming-soon/:comingsoon",
    // email templates
    indexEmailTemplate: "email_templates",
    showEmailTemplate: "email_templates/:emailTemplate",
    updateEmailTemplate: "email_templates/:emailTemplate",
    deleteEmailTemplate: "email_templates/:emailTemplate",
    storeEmailTemplate: "email_templates",
    // email templates
    sendEmail: "send_email/:user",
    // app section
    indexAppSection: "app-sections/home/get-by-page",
    // special item or feature
    indexSpecialItem: "admin-features",
    indexMusicSI: "admin-features/by-type/mp3s",
    indexVideoSI: "admin-features/by-type/videos",
    indexPlaylistSI: "admin-features/by-type/playlists",
    indexComposerSI: "admin-features/by-type/composers",
    indexBannersSI: "admin-features/by-type/banners",
    indexNewsSI: "admin-features/by-type/news",
    indexLatestSI: "admin-features/by-type/latest",
    indexRadioSI: "admin-features/by-type/media",
    indexMoodsSI: "admin-features/by-type/moods",
    indexfeaturedSI: "admin-features/by-type/featured",
    storeSpecialItem: "admin-features",
    showSpecialItem: "admin-features/:specialItem",
    updateSpecialItem: "admin-features/:specialItem",
    destroySpecialItem: "admin-features/:specialItem",
    // placeholder
    indexPlaceholder: "placeholders",
    // news
    // indexNews: 'get-news',
    // country
    indexCountries: "countries",
    //country code
    indexCountryCode: "countries-code",
    // city
    indexCities: "cities/:city",
    // genre
    indexGenre: "admin-genres",
    // role
    indexRole: "admin-roles",
    // file
    storeFile: "files",
    // setting
    indexSetting: "get-settings",
    storeSetting: "admin-settings",
    // version
    indexVersions: "admin-versions",
    storeVersion: "admin-versions",
    deleteVersion: "admin-versions/:version",
    // auth
    login: "admin-login",
    forgotPassword: "forgot-password",
    forgetPasswordVerify: "forgot-password-verify",
    resetPassword: "reset-password",
    checkExistUserName: "check-exist-user-name",
    //profile
    indexProfilePosts: "my-posts",
    //wallet
    usersWallet: "admin-wallet/users-wallet/:id",
    userTransactionList: "admin-wallet/users-transactions/:id",
    allCurrencies: "admin-wallet/currency",
    giveBonusToUser: "admin-wallet/payment/give-bonus-to-user",
    addCurrencyType: "admin-wallet/currency",
    showCurrency: "admin-wallet/currency/:id",
    editCurrencyTypes: "admin-wallet/currency/:id",
    adminLogList: "admin-wallet/admin-logs",
    logDetail: "admin-wallet/admin-logs/:id",
    totalsPaymentsData: "admin-wallet/total-payments",
    totalPaymentList: "admin-wallet/payments",
    totalPayments: "admin-wallet/payments/:id",
    totalTransactionList: "admin-wallet/transactions",
    totalTransactions: "admin-wallet/transactions/:id",
    sendMultipleBonuses: "admin-wallet/payment/give-bonus-to-some-users",
    getReferalCode: "admin-wallet/referral/latest-reward-amounts",
    setReferalCode: "admin-wallet/referral/reward-amounts",
    getConfigs: "admin-wallet/withdraw/config",
    setConfig: "admin-wallet/withdraw/config",
    whitdrawRequests:"admin-wallet/withdraw/requests",
    getResponse:"admin-wallet/withdraw/requests/:id",
    storeResponse:"admin-wallet/withdraw/response/:id",
    //Store
    addStoreSlideShow: "admin-store/slideshow",
    deleteStoreSlideshow: "admin-store/slideshow/:slideshow",
    showStoreSlideshow: "admin-store/slideshow/:slideshow",
    updateStoreSlideshow: "admin-store/slideshow/:slideshow",
    storeStoreItem: "admin-store/items",
    showMainStoreItem: "admin-store/items/:storeId",
    showStoreItem: "admin-store/stickers-by-pack/:storeId",
    updateStoreItem: "admin-store/items/:item",
    addNewStickerToStoreItem: "admin-store/stickers",
    showStoreItemSticker: "admin-store/stickers/:item",
    updateStoreItemSticker: "admin-store/stickers/:item",
    deleteStoreItemSticker: "admin-store/stickers/:item",
    stickerSendToTop: "admin-store/stickers/:item/send-to-top",
    packSendToTop: "admin-store/items/:item/send-to-top",
    //contest
    storeContest: "admin-contest",
    indexContests: "admin-contest",
    showContest: "admin-contest/:id",
    updateContest: "admin-contest/:id",
    deleteContest: "admin-contest/:id",
    storeStageContest: "admin-contest/stage",
    indexStageContests: "admin-contest/:stageId/stages",
    showStageContest: "admin-contest/stage/:id",
    updateStageContest: "admin-contest/stage/:id",
    storeGroupContest: "admin-contest/group",
    fetchGroupContest: "admin-contest/:stageId/groups",
    showContestGroup: "admin-contest/group/:groupId",
    updateContestGroup: "admin-contest/group/:id",
    showParticipant: "admin-contest/participant/:participantId",
    storeCandidates: "admin-contest/:contestId/candidate",
    contestCandidatesList: "admin-contest/:contestId/candidates",
    removeCandidate: "admin-contest/candidate/:id",
    storeSong: "admin-contest/song",
    stageSongsindex: "admin-contest/:stageId/songs",
    showStageSong: "admin-contest/song/:songId",
    updateStageMusics: "admin-contest/song/:id",
    publishAParticipantVideo: "admin-contest/video/:videoId",
};

export default urlGenerator(urls);
