export default {
   //Modal
   SET_RESULT_CURRENT_MODAL(state, result) {
		state.result = result;
	},
   SET_PROPERTIES_CURRENT_MODAL(state, properties) {
		state.properties = properties;
   },

   //Dialog
   SET_DIALOG_TYPE(state, type = 'delete') {
      state.dialogType = type;
   },
   SET_DIALOG_PROPERTIES(state, properties = {}) {
      state.dialogProperties = properties;
   },
};
