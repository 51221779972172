import axios from 'axios'
import { TokenStorage } from '../storage'

const ApiService = {

    init(baseURL = null) {
        if (baseURL) {
            axios.defaults.headers.common['accept-language'] = 'en'
            axios.defaults.baseURL = baseURL
        } else if (process.env.MIX_PUSHER_APP_API) {
            axios.defaults.baseURL = process.env.MIX_PUSHER_APP_API
        }

        if (TokenStorage.getToken()) {
            this.setAuthHeader()
        }
    },

    setAuthHeader() {
        axios.defaults.headers.common.Authorization = `Bearer ${TokenStorage.getToken()}`
    },
    removeAuthHeader() {
        axios.defaults.headers.common.Authorization = null
    },

}

export default ApiService
