import { mapActions } from 'vuex'
// import $_color from "@Global/utils/module-color";
import Vue from 'vue'

const modal = {
    ...mapActions('modal', [
        "$_dialog",
        "$_helper",
        "__openModal",
        "$_closeModal"
    ]),
    $_openModal(name, data = {}) {
        if (typeof name == 'object') {
            this.__openModal(name)
        } else {
            this.__openModal({...data, ...{name}})
        }
    },
}

Vue.mixin({
    methods: { ...modal },
})
