import axios from 'axios'
import url from '../url'
import { getArray } from '../resources/countryResource'

export default class CountryRepository {
    async index() {
        const response = await axios.get(url('indexCountries'))
        if (response.status === 200) {
            return getArray(response.data)
        }
    }
}
