export const getJson = data => ({
    id: data.id,
    name: data.name,
    name_ar: data.name_ar,
    subject: data.subject,
    subject_ar: data.subject_ar,
    image: data.image,
    thumbnail: data.thumbnail,
})
export const getArray = ({ data }) => {
    data = data.map(genre => getJson(genre))
    return { data }
}
