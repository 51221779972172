export default {
    properties: null,
    result: null,

    modal:{
        dialog: false,
    },
    modals: [],
    rel: [],
    model: [],
    dialogProperties: {},
    dialogType: {},
    page_type: null,
};
