import axios from 'axios'
import url from '../url'
import {
    getArray, setQuery, getJson, setData,
} from '../resources/artistResource'

export default class ArtistRepository {
    async index(data) {
        const params = setQuery(data)
        const response = await axios.get(url('indexArtists'), { params })
        if (response.status === 200) {
            return getArray(response.data)
        }
    }

    async show(artisId) {
        const response = await axios.get(url('showArtist', { artist: artisId }))
        if (response.status === 200) {
            return getJson(response.data.data)
        }
    }

    async store(data) {
        const json = setData(data)
        const response = await axios.post(url('storeArtist'), json)
        if (response.status === 201) {
            return getJson(response.data)
        }
    }

    async update(userId, data) {
        const json = setData(data)
        const response = await axios.put(
            url('updateArtist', { artist: userId }),
            json,
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async delete(userId) {
        await axios.delete(url('destroyUser', { user: userId }))
    }
}
