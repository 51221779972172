import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import VueChatScroll from 'vue-chat-scroll';
import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// Utils
import ApiService from "@/utils/axios/api";
import "@/utils/axios/errorHandler";
// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";
// Require dependencies
var VueCookie = require("vue-cookie");
// Tell Vue to use the plugin
Vue.use(VueCookie);

// Axios Mock Adapter
import "@/@fake-db/db";

if (window.location.hostname === "dev.nojoum.app") {
    ApiService.init("https://develop.nojoum.app");
} else {
    ApiService.init("https://nojoum.app");
}
//saeidkhakbazan@gmail.com
//12345678
// 'http://192.168.1.55:8000'
// 'https://nojoum.app'
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueChatScroll);
// Composition API
Vue.use(VueCompositionAPI);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");
require("@/assets/css/style.css");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
