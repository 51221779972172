import 'willatoast/dist/main.css'
import willaToast from 'willatoast'

const toast = {
    error: (message, title = 'Error') => willaToast.normal({
        title,
        titleColor: '#e10000',
        message,
        position: 'bottomCenter',
    }),
    success: (message, title = 'Success') => willaToast.normal({
        title,
        message,
        position: 'bottomCenter',
    }),
    question: (message, title = 'Success', callback) => willaToast.normal({
        title,
        message,
        position: 'center',
        timeout: false,
        theme: 'dark',
        buttons: [
            ['<button style="border: 1px dashed white;" >yes</button>', function (instance, toast) {
                callback()
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
            }, true],
            ['<button>no</button>', function (instance, toast) {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
            }],
        ],
    }),
}

export default toast
