<template>
  <b-modal
    v-model="modal"
    :size="size"
    hide-header-close
    :header-bg-variant="headerBgVariant"
    :header-text-variant="headerTextVariant"
    :body-bg-variant="bodyBgVariant"
    :body-text-variant="bodyTextVariant"
    :footer-bg-variant="footerBgVariant"
    :footer-text-variant="footerTextVariant"
  >
    <template #modal-title>
      <div class="wa__modal-header">
        <slot
          v-if="modal"
          name="header"
          :modal="modal"
          :data="data"
        />
      </div>
    </template>
    <b-card class="wa__modal">
      <div class="w-100">
        <slot
          v-if="modal"
          :modal="modal"
          :data="data"
        />
      </div>
    </b-card>
    <template #modal-footer>
      <div class="w-100">
        <slot
          v-if="modal"
          name="footer"
          :modal="modal"
          :data="data"
        />
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import {
    BModal, BCard, BCardFooter, BCardText,
} from 'bootstrap-vue'

export default {
    components: {
        BModal,
        BCard,
        BCardFooter,
        BCardText,
    },
    props: {
        name: { default: null },
        size: { default: 'lg' },
        headerBgVariant: {
            type: String,
            default: '',
        },
        headerTextVariant: {
            type: String,
            default: 'light',
        },
        bodyBgVariant: {
            type: String,
            default: '$theme-dark-card-bg',
        },
        bodyTextVariant: {
            type: String,
            default: 'light',
        },
        footerBgVariant: {
            type: String,
            default: '$theme-dark-card-bg',
        },
        footerTextVariant: {
            type: String,
            default: 'light',
        },
    },
    data: () => ({
        isOpenModal: false,
    }),
    computed: {
        ...mapGetters('modal', ['isModal', 'getModal']),
        modal: {
            get() {
                const isOpen = this.isModal(this.modalName)
                this.emitModal(isOpen)
                return isOpen
            },
            set(value) {
                if (!value) {
                    this.$_closeModal()
                }
            },
        },
        modalName() {
            return this.name ? this.name : this.$parent.$options.name
        },
        data() {
            return this.getModal(this.modalName)
        },
    },
    beforeDestroy() {
        this.emitModal(false)
    },
    methods: {
        emitModal(isOpen) {
            if (this.isOpenModal && !isOpen) {
                this.isOpenModal = false
                this.$emit('close')
            } else if (!this.isOpenModal && isOpen) {
                this.isOpenModal = true
                this.$emit('open', this.data)
                this.$parent.modalData = this.data
            }
        },
    },
}
</script>
<style lang="scss">
@import "~@core/scss/base/core/colors/palette-variables.scss";

.wa__modal {
  box-shadow: none !important;
}
.modal-header {
  padding: 27px 0 27px 0 !important;
  text-align: center;
  justify-content: center !important;
}
.modal-footer {
  padding: 1rem 2.6rem !important;
}
</style>
