import { SetQueriesObject } from '@/utils/setQueriesObject'

export const getJson = data => ({
    id: data.id,
    page: data.page,
    title: data.title,
    sub_title: data.sub_title,
    type: data.type,
    summary: data.summary,
    sort: data.sort,
    display_type: data.display_type,
    created_at: data.created_at,
    style: data.style,
    features: data.features,
})
export const getArray = ({ data }) => {
    data = data.map(appSection => getJson(appSection))
    return data
}
export const setQuery = data => SetQueriesObject(data)
