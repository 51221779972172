var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-2"},[_c('cropper',{class:{ 'upload-example-cropper': true },attrs:{"stencil-props":_vm.stencilProps,"src":_vm.thumbnail,"canvas":false,"check-orientation":false},on:{"change":_vm.onChangeCropper}})],1),_c('div',{staticClass:"wa__modal__form"},[_c('div',[(_vm.hiddenInput)?_c('b-button',{staticClass:"w-100",attrs:{"text":"select your file","icon":"WMi-camera","variant":"danger"},on:{"click":function($event){return _vm.$refs.fileInput.$refs.input.click()}}},[_c('span',[_c('i',{staticClass:"WMi-camera"})])]):_vm._e(),_c('b-form-file',{ref:"fileInput",style:([
          _vm.hiddenInput
            ? {
              visibility: 'hidden',
              height: '0',
              padding: '0',
            }
            : '' ]),attrs:{"accept":"image/jpeg, image/png","placeholder":"Pick an Image","prepend-icon":"WMi-camera","label":_vm.label,"dark":_vm.dark},on:{"input":_vm.changeImage}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }